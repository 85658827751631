<template>
    <div class="login-group">
      <b-container id="container" class="max-100">
        <b-row class="max-100">
          <b-col cols="1"></b-col>
          <b-col cols="10">
            <b-container fluid>
                <b-row class="max-100" align-h="center">
                    <b-img id="logo" src="../assets/Menu_logo.png"></b-img>
                </b-row>
                <div id="offline" class="max-100" v-if="offline">
                    <div>App is offline. You will need to have internet in order to log in.</div>
                </div>
                <b-row id="errormsg" class="max-100" align-h="center" v-if="error" :error="error">{{ error }}</b-row>
                <b-row id="successmsg" class="max-100" align-h="center" v-if="successmsg" :error="successmsg">{{ successmsg }}</b-row>
            </b-container>

            <!-- CREDENTIALS FORM -->
            <form id="form" @submit.prevent="submit">
                <div>
                    <input type="text" name="user_id" id="user-id"
                        placeholder="UserID" v-model="form.user_id">
                </div>
                <div>
                    <input type="password" name="user_password" id="password"
                        placeholder="Password" v-model="form.user_password">
                </div>
                <div >
                    <input id="submit" type="submit" value="ENTER">
                </div>

            </form>

            <!-- PASSWORD RESET LINK -->
            <b-container fluid>
                <b-row id="password-reset-link" @click.prevent="goPasswordReset" align-h="center">
                    <input id="password-reset-submit" type="submit" value="Forgot Password?">
                </b-row>
            </b-container>

          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'signin',
    computed: {
      ...mapGetters({
          offline: 'loadingmsg/offline',
          error: 'auth/error',
          successmsg: 'auth/successmsg'
      }),

    },

    data () {
      return {
          form: {
              user_id: '',
              user_password: '',
          }

      }

    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
            setError: 'auth/setError',
            setSuccessMsg: 'auth/setSuccessMsg',
            getPasswordToken: 'password/getPasswordToken',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg',
        }),

        submit() {
            this.setSuccessMsg(null)
            if (this.form.user_id === '') {
                this.setError('UserID is needed.');
                return

            } else if (this.form.user_password === '') {
                this.setError('Password is needed.');
                return

            } else {
                this.turnOnLoadingMsg()
                // console.log('submitting creds')
                this.signIn(this.form).then((res) => {
                    if(res) {
                        this.$router.replace({
                            name: 'home'
                        }).then(() => {
                            this.turnOffLoadingMsg();
                        }).catch(() => {})

                    } else {
                        this.$router.replace({
                            name: 'signin'
                        }).catch(() => {})
                        this.turnOffLoadingMsg();

                    }

                }).catch(() => {})

            }


        },

        goPasswordReset() {
            this.setError(null);
            this.getPasswordToken().then(() => {
                this.$router.replace({
                    name: 'password'
                })

            })

        },

    }

}
</script>

<style scoped>
    #offline {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        text-align: center;
        color: red;
    }
    .login-group {
        min-height: 100vh;

    }
    .max-100 {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-width: 100%;
        max-width: 100%;
    }

    #container {
      padding: 0 0 0 0;
      padding-top: 3.5em;
    }
    #logo {
        width: 40vw;
        max-width: 200px;
        height: 40vw;
        max-height: 200px;
        margin-bottom: 2vh;
    }

    #errormsg {
        margin-bottom: 2vh;
        color: red;
        font-weight: bold;
    }

    #successmsg {
        margin-bottom: 2vh;
        color: #00FA9A;
        font-weight: bold;
    }

    #form {
        max-width: 300px;
        max-width: 90vw;
    }

    #user-id, #password {
        width: 100%;
        padding: 6px;
        min-height: 30px;
        max-height: 50px;
        margin-bottom: 10px;
        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid Gray;
    }
    #submit {
        background-color: transparent;
        color: Gray;
        margin-top: 20px;
        padding: .25em;
        border: 1px solid LightGray;
        border-radius: .5em;
        width: 50%;
        transform: translateX(50%);
    }

    #submit:active {
      transform: translate(50%,3px);
    }

    #password-reset-link {
        margin-top: 2em;
    }

    #password-reset-submit {
        background-color: transparent;
        color: #0f70b7;
        font-weight: bold;
        border: none;
        width: 100%;

    }

</style>
